


const Dashboard = () => {

  return (
    <div>
      <p>Dashboard</p>
    </div>
  );
};

export default Dashboard;
