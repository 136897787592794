import loadable from "@loadable/component";
import APIS from "../../contants/EndPoints";
import useDeleteHook from "../../customHooks/useDeleteHook";

const BlogComponent = loadable(() => import("../../components/Blog/blog"));

const Blog = () => {

  const { mutateAsync: deleteMutateAsync } = useDeleteHook({ queryKey: ["blog"] })

  const handleDelete = async (setOpenPopup: any, pk: string) => {
    const url = `${APIS.BLOG}${pk}/`
    try {
      const res = await deleteMutateAsync({ url })
      console.log(res, "ii")
      setOpenPopup(false)
    } catch (err) {
      console.log(err);
    }
  }


  return (
    <>
      <BlogComponent
        // data={blog}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default Blog;


