import loadable from "@loadable/component";
import APIS from "../../contants/EndPoints";
import { useNavigate } from "react-router-dom";
import usePostFormDataHook from "../../customHooks/useFormDataPostHook";

const AddTagsComponent = loadable(() => import("../../components/Tags/CreateTags"));

const AddTags = () => {
  const { isPostLoading, mutateAsync, postData } = usePostFormDataHook({ queryKey: ["tag"], navigateURL: null })
  const navigate = useNavigate()
  const handleCreateTag = async (values: any) => {

    const url = APIS.TAG
    let formData = new FormData()
    formData.append("name", values.tag_name)
    formData.append("description", values.tag_description)
    formData.append("preview_image", values.image)
    try {
      const res = await mutateAsync({ url, formData })
      navigate(`/edit-tags/${res.data.id}`)

    } catch (err) {
      console.log(err);
    }
  }
  return (
    <>
      <div>
        <AddTagsComponent
          handleCreateTag={handleCreateTag}
        />
      </div>
    </>
  );
};

export default AddTags;