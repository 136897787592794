import React from "react";
import loadable from "@loadable/component";
import APIS from "../../contants/EndPoints";
import useDeleteHook from "../../customHooks/useDeleteHook";

const CategoryComponent = loadable(() => import("../../components/Category/category"));

const Category = () => {

  const { mutateAsync: deleteMutateAsync } = useDeleteHook({ queryKey: ["catagories"] })

  const handleDelete = async (setOpenPopup: any, pk: string,) => {
    const url = `${APIS.CATEGORY}${pk}/`
    try {
      const res = await deleteMutateAsync({ url })
      console.log(res)
      setOpenPopup(false)
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <>
      <CategoryComponent

        handleDelete={handleDelete}
      />
    </>
  );
};

export default Category;
