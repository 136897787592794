const APIS = {
  // Pages
  BLOG: `/blog/`,
  CATEGORY: `/category/`,
  TAG: `/tag/`,
  MEDIA: `/media/`,
  USERS: `/user/`,
  METADATA: '/meta-description/',
  AUTHOR: '/author/',
  //THEMES
  THEMES: `/theme/`,
  CHANGE_THEME: `/change-theme/`,
  THEME_COMPONENTS: `/theme-templates/`,

};

export default APIS;
