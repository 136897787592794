import loadable from "@loadable/component";
import { useParams } from "react-router-dom";
import APIS from "../../contants/EndPoints";
import useGetHook from "../../customHooks/useGetHook";
import usePatchHook from "../../customHooks/usePatchHook";
import usePatchFormDataHook from "../../customHooks/useFormDataPatchHook";

const EditCategoryComponent = loadable(() => import("../../components/Category/EditCategory"));

const EditCategory = () => {
  const { id } = useParams()
  const { mutateAsync } = usePatchFormDataHook({ queryKey: ["categories", `categories-${id}`], navigateURL: "/category" })
  const { isLoading: catagoryLoadi, mutateAsync: metaDataMutateAsync, data: metaData } = usePatchHook({ queryKey: ["categories", `categories-${id}`], navigateURL: null })


  const { data: category, isLoading: categoryLoading } = useGetHook({
    queryKey: [`categories-${id}`],
    url: `${APIS.CATEGORY}${id}/`,
    params: {}
  });

  const handleUpdate = async (values: any) => {
    const url = `${APIS.CATEGORY}${id}/`
    let formData = new FormData()
    formData.append("name", values.category_title)
    formData.append("description", values.category_description)
    formData.append("preview_image", values.image)

    try {
      await mutateAsync({ url, formData })
    } catch (err) {
      console.log(err);
    }

  }
  const handleSeoUpdate = async (values: any) => {
    const url = `${APIS.METADATA}${category?.seo_meta?.id}/`
    const formData = {
      title: values.seo_title,
      slug: values.slug,
      description: values.description,
      keywords: values.keywordPhase?.join(",")
    }
    console.log(formData)
    try {
      await metaDataMutateAsync({ url, formData })
    } catch (err) {
      console.log(err);
    }
  }


  return (
    <>{category ?
      <EditCategoryComponent
        category={category}
        handleUpdate={handleUpdate}
        handleSeoUpdate={handleSeoUpdate}
      /> : "Loading"
    }
    </>
  );
};

export default EditCategory;