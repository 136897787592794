import loadable from "@loadable/component";
import { useParams } from "react-router-dom";
import usePatchHook from "../../customHooks/usePatchHook";
import useGetHook from "../../customHooks/useGetHook";
import APIS from "../../contants/EndPoints";
import usePatchFormDataHook from "../../customHooks/useFormDataPatchHook";
import moment from "moment";

const EditBlogComponent = loadable(() => import("../../components/Blog/EditBlog"));

const EditBlog = (props: any) => {
  const { id } = useParams()
  const { mutateAsync } = usePatchFormDataHook({ queryKey: ["blogs", `blogs-${id}`], navigateURL: "/blogs" })
  const { isLoading: metaLoading, mutateAsync: metaDataMutateAsync, data: metaData } = usePatchHook({ queryKey: ["blogs", `blogs-${id}`], navigateURL: null })

  const { data: blog, isLoading: blogLoading } = useGetHook({
    queryKey: [`blogs-${id}`],
    url: `${APIS.BLOG}${id}/`,
    params: {}
  });
  const handleUpdate = async (values: any) => {
    const url = `${APIS.BLOG}${id}/`
    let formData = new FormData()
    formData.append("headline", values.blog_title)
    formData.append("summery", values.blog_description)
    formData.append("author", values.author)
    if (values.publish_date) {
      formData.append("start", values.publish_date)
    }
    if (values.expire_date) {
      formData.append("end", values.expire_date)
    }
    if (values.image) {
      formData.append("preview_image", values.image)
    }
    formData.append("is_published", values.is_active)
    formData.append("content", JSON.stringify(values.content))
    formData.append("categories", values.category?.map((item: any) => (item.name)).join(",") ?? " ")
    formData.append("tags", values.tags?.map((item: any) => (item.name)).join(",") ?? " ")
    try {
      await mutateAsync({ url, formData })
    } catch (err) {
      console.log(err);
    }
  }

  const handleSeoUpdate = async (values: any) => {
    const url = `${APIS.METADATA}${blog?.seo_meta?.id}/`
    const formData = {
      title: values.seo_title,
      slug: values.slug,
      description: values.description,
      keywords: values.keywordPhase?.join(",")
    }
    console.log(formData)
    try {
      await metaDataMutateAsync({ url, formData })
    } catch (err) {
      console.log(err);
    }
  }
  const { data: authors, isLoading: authorLoading } = useGetHook({
    queryKey: ["author"],
    url: `${APIS.AUTHOR}`,
    params: {}
  });
  const { data: categories, isLoading: categoryLoading } = useGetHook({
    queryKey: ["categories"],
    url: `${APIS.CATEGORY}`,
    params: {}
  });
  const { data: tags, isLoading: tagsLoading } = useGetHook({
    queryKey: ["tags"],
    url: `${APIS.TAG}`,
    params: {}
  });
  return (
    <>
      {blog ?
        <EditBlogComponent
          blog={blog}
          authors={authors}
          tags={tags}
          categories={categories}
          handleUpdate={handleUpdate}
          handleSeoUpdate={handleSeoUpdate}
        /> : "Loading"
      }
    </>
  );
};

export default EditBlog;