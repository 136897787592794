import loadable from "@loadable/component";
import APIS from "../../contants/EndPoints";
import { useNavigate } from "react-router-dom";
import usePostFormDataHook from "../../customHooks/useFormDataPostHook";

const AddCategoryComponent = loadable(() => import("../../components/Category/CreateCategory"));

const AddCategory = () => {
  const { isPostLoading, mutateAsync, postData } = usePostFormDataHook({ queryKey: ["categories"], navigateURL: null })
  const navigate = useNavigate()
  const handleCreateCategory = async (values: any) => {
    let formData = new FormData()
    formData.append("name", values.category_title)
    formData.append("description", values.category_description)
    formData.append("preview_image", values.image)
    const url = APIS.CATEGORY
    try {
      const res = await mutateAsync({ url, formData })
      navigate(`/edit-category/${res.data.id}`)
    } catch (err) {
      console.log(err);
    }

  }
  return (
    <>
      <AddCategoryComponent
        handleCreateCategory={handleCreateCategory}
      />
    </>
  );
};

export default AddCategory;