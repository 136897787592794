import loadable from "@loadable/component";
import React from 'react'
import useDeleteHook from "../../customHooks/useDeleteHook";
import APIS from "../../contants/EndPoints";
const AuthorComponent = loadable(() => import("../../components/Author/author"));

const author = () => {

  const { mutateAsync: deleteMutateAsync } = useDeleteHook({ queryKey: ["author"] })

  const handleDelete = async (setOpenPopup: any, pk: string) => {
    const url = `${APIS.AUTHOR}${pk}/`
    try {
      const res = await deleteMutateAsync({ url })
      console.log(res, "ii")
      setOpenPopup(false)
    } catch (err) {
      console.log(err);
    }
  }


  return (
    <AuthorComponent
      handleDelete={handleDelete} />
  )
}

export default author