import loadable from "@loadable/component"
import APIS from "../../contants/EndPoints"
import useGetHook from "../../customHooks/useGetHook"
import { useNavigate } from "react-router-dom";
import usePostFormDataHook from "../../customHooks/useFormDataPostHook";

const AddBlogComponent = loadable(() => import("../../components/Blog/CreateBlog"));

const AddBlog = () => {
  const { isPostLoading, mutateAsync, postData } = usePostFormDataHook({ queryKey: ["blog"], navigateURL: null })
  const navigate = useNavigate()
  const handleCreateBlog = async (values: any) => {
    const url = APIS.BLOG
    let formData = new FormData()
    formData.append("headline", values.blog_title)
    formData.append("summery", values.blog_description)
    formData.append("author", values.author)
    if (values.publish_date) {
      formData.append("start", values.publish_date)
    }
    if (values.expire_date) {
      formData.append("end", values.expire_date)
    }
    if (values.image) {
      formData.append("preview_image", values.image)
    }
    formData.append("is_published", values.is_active)
    formData.append("content", JSON.stringify(values.content))
    formData.append("categories", values.category?.map((item: any) => (item.name)).join(",") ?? " ")
    formData.append("tags", values.tags?.map((item: any) => (item.name)).join(",") ?? " ")
    try {
      const res = await mutateAsync({ url, formData })
      navigate(`/edit-blog/${res.data.id}`)

    } catch (err) {
      console.log(err);
    }
  }
  const { data: tags, isLoading: tagLoading } = useGetHook({
    queryKey: ["tag"],
    url: `${APIS.TAG}`,
    params: {}
  });

  const { data: categories, isLoading: categoryLoading } = useGetHook({
    queryKey: ["categories"],
    url: `${APIS.CATEGORY}`,
    params: {}
  });
  const { data: authors, isLoading: authorLoading } = useGetHook({
    queryKey: ["author"],
    url: `${APIS.AUTHOR}`,
    params: {}
  });


  return (
    <>
      {tagLoading || categoryLoading ?
        "" : <AddBlogComponent
          handleCreateBlog={handleCreateBlog}
          tags={tags}
          categories={categories}
          authors={authors}
        />
      }
    </>
  );
};

export default AddBlog;