import React from "react";
import loadable from "@loadable/component";
import APIS from "../../contants/EndPoints";
import useDeleteHook from "../../customHooks/useDeleteHook";

const TagComponent = loadable(() => import("../../components/Tags/tags"));

const Tag = () => {
  const { mutateAsync: deleteMutateAsync } = useDeleteHook({ queryKey: ["tag"] })

  const handleDelete = async (setOpenPopup: any, pk: string,) => {
    const url = `${APIS.TAG}${pk}/`
    try {
      const res = await deleteMutateAsync({ url })
      console.log(res, "delete")
      setOpenPopup(false)
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <>
      <TagComponent
        handleDelete={handleDelete}
      />
    </>
  );
};

export default Tag;
