import loadable from "@loadable/component";
import { useParams } from "react-router-dom";
import APIS from "../../contants/EndPoints";
import useGetHook from "../../customHooks/useGetHook";
import usePatchHook from "../../customHooks/usePatchHook";
import usePatchFormDataHook from "../../customHooks/useFormDataPatchHook";

const EditTagComponent = loadable(() => import("../../components/Tags/EditTag"));

const EditTag = () => {
  const { id } = useParams()
  const { mutateAsync } = usePatchFormDataHook({ queryKey: ["tags", `tags-${id}`], navigateURL: "/tags" })
  const { isLoading: seoLoading, mutateAsync: metaDataMutateAsync, data: metaData } = usePatchHook({ queryKey: ["tags", `tags-${id}`], navigateURL: null })


  const { data: tag, isLoading: tagLoading } = useGetHook({
    queryKey: [`tags-${id}`],
    url: `${APIS.TAG}${id}/`,
    params: {}
  });
  const handleUpdate = async (values: any) => {
    const url = `${APIS.TAG}${id}/`
    let formData = new FormData()
    formData.append("name", values.tag_name)
    formData.append("description", values.tag_description)
    formData.append("preview_image", values.image)

    try {
      await mutateAsync({ url, formData })
    } catch (err) {
      console.log(err);
    }
  }
  const handleSeoUpdate = async (values: any) => {
    const url = `${APIS.METADATA}${tag?.seo_meta?.id}/`
    const formData = {
      title: values.seo_title,
      slug: values.slug,
      description: values.description,
      keywords: values.keywordPhase?.join(",")
    }
    console.log(formData)
    try {
      await metaDataMutateAsync({ url, formData })
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <>{tag ?
      <EditTagComponent
        tag={tag}
        handleUpdate={handleUpdate}
        handleSeoUpdate={handleSeoUpdate}
      /> : "Loading"
    }
    </>
  );
};

export default EditTag;