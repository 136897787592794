import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PageNotFound from "../pages/ErrorPage/PageNotFound";
import DashboardLayout from "../layouts/DashboardLayout";
import Dashboard from "../components/Dashboard";
import EditCategory from "../pages/Category/EditCategory";
import EditBlog from "../pages/Blog/EditBlog";
import AddBlog from "../pages/Blog/AddBlog";
import AddCategory from "../pages/Category/AddCategory";
import AddTags from "../pages/Tags/AddTags";
import EditTags from "../pages/Tags/EditTags";
import Category from "../pages/Category/Category";
import Blog from "../pages/Blog/Blog";
import Tag from "../pages/Tags/Tag";
import Author from "../pages/Author/author";
import AddAuthor from "../pages/Author/AddAuthor";
import EditAuthor from "../pages/Author/EditAuthor";

// import AccessControl from "../helpers/accessControl";
// import PermissionDenied from "../pages/Error/PermissionDenied";

// https://adarshaacharya.com.np/blog/role-based-auth-with-react-router-v6

/**
 * Top level application router
 *
 * @returns {JSX.Element}
 */
const Router = () => {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute>
            <DashboardLayout />
          </PrivateRoute>
        }
      >
        <Route path={"/"} element={<Dashboard />} />
        <Route path={"/blogs"} element={<Blog />} />
        <Route path={"/tags"} element={<Tag />} />
        <Route path={"/author"} element={<Author />} />
        <Route path={"/category"} element={<Category />} />
        <Route path={"/edit-category/:id"} element={<EditCategory />} />
        <Route path={"/add-category"} element={<AddCategory />} />
        <Route path={"/add-blog"} element={<AddBlog />} />
        <Route path={"/add-author"} element={<AddAuthor />} />
        <Route path={"/edit-blog/:id"} element={<EditBlog />} />
        <Route path={"/add-tags"} element={<AddTags />} />
        <Route path={"/edit-tags/:id"} element={<EditTags />} />
        <Route path={"/edit-author/:id"} element={<EditAuthor />} />

        <Route path="/*" element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

export default Router;
