import React from 'react'
import loadable from "@loadable/component"
import { useParams } from 'react-router-dom';
import usePatchHook from '../../customHooks/usePatchHook';
import useGetHook from '../../customHooks/useGetHook';
import APIS from '../../contants/EndPoints';
import usePatchFormDataHook from '../../customHooks/useFormDataPatchHook';

const EditAuthorComponent = loadable(() => import("../../components/Author/EditAuthor"));

const EditAuthor = () => {
  const { id } = useParams()
  const { mutateAsync } = usePatchFormDataHook({ queryKey: ["authors", `authors-${id}`], navigateURL: "/author" })

  const { isLoading: dataLoading, mutateAsync: metaDataMutateAsync, data: authorData } = usePatchHook({ queryKey: ["authors", `authors-${id}`], navigateURL: null })

  const { data: author, isLoading: authorLoading } = useGetHook({
    queryKey: [`authors-${id}`],
    url: `${APIS.AUTHOR}${id}/`,
    params: {}
  });

  const handleUpdate = async (values: any) => {
    const url = `${APIS.AUTHOR}${id}/`
    let formData = new FormData()
    formData.append("display_name", values.display_name)
    formData.append("summery", values.description)
    formData.append("preview_image", values.image)

    try {
      await mutateAsync({ url, formData })
    } catch (err) {
      console.log(err);
    }

  }
  const handleSeoUpdate = async (values: any) => {
    const url = `${APIS.METADATA}${author?.seo_meta?.id}/`
    const formData = {
      title: values.seo_title,
      slug: values.slug,
      description: values.description,
      keywords: values.keywordPhase?.join(",")
    }
    console.log(formData)
    try {
      await metaDataMutateAsync({ url, formData })
    } catch (err) {
      console.log(err);
    }
  }


  return (<> {
    author ?
      <EditAuthorComponent
        handleUpdate={handleUpdate}
        handleSeoUpdate={handleSeoUpdate}
        author={author} /> : "Loading"
  }</>

  )
}

export default EditAuthor