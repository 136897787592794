import loadable from "@loadable/component"
import React from 'react'
import APIS from "../../contants/EndPoints";
import { useNavigate } from "react-router-dom";
import usePostFormDataHook from "../../customHooks/useFormDataPostHook";
const AddAuthorComponent = loadable(() => import("../../components/Author/CreateAuthor"));

const AddAuthor = () => {
  const { isPostLoading, mutateAsync, postData } = usePostFormDataHook({ queryKey: ["author"], navigateURL: null })
  const navigate = useNavigate()
  const handleCreateAuthor = async (values: any,setError:any) => {
    const url = APIS.AUTHOR
    let formData = new FormData()
    formData.append("display_name", values.display_name)
    formData.append("summery", values.description)
    formData.append("preview_image", values.image)

    try {
      const res = await mutateAsync({ url, formData,setError })
      navigate(`/edit-author/${res.data.id}`)

    } catch (err) {
      console.log(err);
    }
  }
  return (
    <AddAuthorComponent
      handleCreateAuthor={handleCreateAuthor}
    />
  )
}

export default AddAuthor